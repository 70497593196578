import "./App.css";
import React from "react";
import * as constant_IP from "./ip"

export default class App extends React.Component {
	state = {
         username : '',
			character_length : '', 
			show_api3 :true,
			id_record:''
		};
		handleChange = (event) => {
			this.setState({username : event.target.value});
		};
			handleChange1 = (event) => {
			this.setState({id_record : event.target.value});
		};
		function_api_1 = (event) => {
			console.log(this.state.username)
			var ttt = {
				name : this.state.username
			}
console.log(constant_IP.IP)
		    fetch(`${constant_IP.IP}/api1`,{
		    		method: 'POST',
		        headers: {  'Content-Type': 'application/json' },
		        body: JSON.stringify(ttt)
		    }).then(response => response.json())
      		.then(data => this.setState({ character_length: "Character Length : "+data.value }));
   	};
   	function_api_2 = (event) => {
			var ttt = {
				name : this.state.username
			}
		    fetch(`${constant_IP.IP}/api2`,{
		    		method: 'POST',
		        headers: {  'Content-Type': 'application/json' },
		        body: JSON.stringify(ttt)
		    }).then(response => response.json())
      		.then(data => {
				 this.setState({ character_length: 'Word Count:' +" : "+ data['value'] })
	      	});	
      	};
   	function_api_3 = (event) => {
			var ttt = {
				name : this.state.username,
				id_record:this.state.id_record
			}
		    fetch(`${constant_IP.IP}/api3`,{
		    		method: 'POST',
		        headers: {  'Content-Type': 'application/json' },
		        body: JSON.stringify(ttt)
		    }).then(response => response.json())
      		.then(data => {
				console.log(data)
				var ttt = data.value
				var values_data = ''
				 Object.keys(ttt).forEach(function(i) {
					values_data += i +' : '+ ttt[i] +','
				})
				values_data = values_data.substring(0, values_data.length - 1);
				this.setState({ character_length: values_data })
				this.setState({ show_api3: false })
				var paragraphs = document.querySelectorAll('.break_word_in_length');
				for (var i = 0; i < paragraphs.length; i++) {
				    paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/,/g, "<br />");
				}
      		});
	};
	function_api_4 = (event) => {
			var ttt = {
				name : this.state.username,
				id_record:this.state.id_record
			}
		    fetch(`${constant_IP.IP}/api4`,{
		    		method: 'POST',
		        headers: {  'Content-Type': 'application/json' },
		        body: JSON.stringify(ttt)
		    }).then(response => response.json())
      		.then(data => {
      			this.setState({ character_length: data.value})
      			this.setState({ show_api3: true})
      		});
	};

		render(){
			return (
				<div className="App">
			      <header className="App-header">
						<p>
						<div className="fitfy_percent_class">
							<label>
								<span>ID: </span>
								<input
									type="text"
									value={this.state.id_record}
									onChange={this.handleChange1}
									name="id_record"
									className="Name-input"
								/>
							</label>
							</div>
						<div className="fitfy_percent_class">
							<label>
								<span>Name: </span>
								<input
									type="text"
									value={this.state.username}
									onChange={this.handleChange}
									name="name"
									className="Name-input"
								/>
							</label>
							</div>
						</p>
						<p>
							<button type="button" className="button_for_api_1" onClick={this.function_api_1}>API 1</button>
							<button type="button" className="button_for_api_1" onClick={this.function_api_2}>API 2</button>
							{ 
								this.state.show_api3 ? 
								<button type="button" className="button_for_api_1" onClick={this.function_api_3}>API 3</button>
							:
								<button type="button" className="button_for_api_1" onClick={this.function_api_4}>API 3 <span>Kill</span></button>
							}
						</p>
                  <p className="break_word_in_length">
                  {this.state.character_length}
                  </p>
					</header>
				</div>
			);
		}
}
